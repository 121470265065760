import React, { Component } from 'react'
import axios from 'axios'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Formik, Form, Field } from 'formik'
import DatePicker from 'react-datepicker'
import Dropzone from 'react-dropzone'
import * as Yup from 'yup'
import classNames from 'classnames'
import Map from 'src/components/Map'
import Thanks from 'src/components/Thanks'
import { addMonths, getBase64 } from 'src/utils'
import styles from './AddJob.module.scss'
import 'react-datepicker/dist/react-datepicker.css'
import ru from 'date-fns/locale/ru'
import en from 'date-fns/locale/en-US'

const AddJobSchema = Yup.object().shape({
  title: Yup.string().required('required'),
  description: Yup.string().required('required'),
  position: Yup.string().required('required'),
  companyName: Yup.string().required('required'),
  salaryfrom: Yup.number().positive(),
  salaryup: Yup.number().positive(),
  city: Yup.string().required('required'),
  contactEmail: Yup.string()
    .email('Invalid email')
    .required('required'),
  contactNumber: Yup.number().positive(),
})

class AddJob extends Component {
  state = {
    isSended: false,
    experienceLevel: this.props.experienceLevel[0].node.originalId,
    tag: this.props.jobTag[0].node.originalId,
    geolocation: { latitude: 53.9045398, longitude: 27.561524400000053 },
    expirationdate: new Date(),
    companyLogo: null,
    photos: [],
  }

  handlePickCoordinates = (event) => {
    const { lat, lng } = event.lngLat
    this.setState({ geolocation: { latitude: lat, longitude: lng } })
  }

  handleSubmit = async (values) => {
    const { locale } = this.props
    const { companyLogo, expirationdate, geolocation, tag } = this.state
    try {
      const res = await axios.post('/.netlify/functions/add-job', {
        locale,
        ...values,
        expirationdate,
        geolocation,
        tag,
        companyLogo: companyLogo && {
          data: await getBase64(companyLogo),
          extension: companyLogo.type.split('/')[1],
        },
      })
      if (res.status === 200) {
        this.setState({
          isSended: true,
        })
      }
    } catch (error) {
      // TODO: Add error handling
      console.log(error)
    }
  }

  render() {
    const { isSended, geolocation, expirationdate, aboutCompany, companyLogo, photos } = this.state
    const { position, intl, locale, experienceLevel } = this.props

    return (
      <div className={styles.root}>
        {isSended && <Thanks successMessageId="home.addJobSuccess" />}
        {!isSended && (
          <div className={styles.container}>
            <h2 className={styles.addJob}>
              <FormattedMessage id="home.addNewJob" />
            </h2>
            <Formik
              onSubmit={this.handleSubmit}
              validationSchema={AddJobSchema}
              render={({ errors, touched, submitCount }) => {
                return (
                  <Form>
                    <div className={styles.row}>
                      <Field
                        type="text"
                        name="title"
                        placeholder={intl.formatMessage({ id: 'home.vacancyName' })}
                        className={classNames(styles.input, {
                          [styles.error]: (errors.title && touched.title) || (errors.title && submitCount),
                        })}
                      />
                    </div>
                    <div className={styles.row}>
                      <Field
                        type="text"
                        name="description"
                        placeholder={intl.formatMessage({ id: 'home.jobDescription' })}
                        className={classNames(styles.input, styles.textarea, {
                          [styles.error]:
                            (errors.description && touched.description) || (errors.description && submitCount),
                        })}
                        component="textarea"
                      />
                    </div>
                    <div className={styles.row}>
                      <div className={styles.choiceField}>
                        <Field
                          name="position"
                          className={classNames(styles.choice, {
                            [styles.error]: (errors.position && touched.position) || (errors.position && submitCount),
                          })}
                          component="select"
                          defaultValue={intl.formatMessage({ id: 'home.position' })}
                        >
                          <option key="default" disabled>
                            {intl.formatMessage({ id: 'home.position' })}
                          </option>
                          {position.map(({ node: { title, originalId } }) => (
                            <option key={originalId} value={originalId}>
                              {title}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </div>
                    <div className={styles.row}>
                      <div className={styles.choiceField}>
                        <Field
                          name="experienceLevel"
                          className={classNames(styles.choice, {
                            [styles.error]:
                              (errors.experienceLevel && touched.experienceLevel) ||
                              (errors.experienceLevel && submitCount),
                          })}
                          component="select"
                          defaultValue={intl.formatMessage({ id: 'home.experienceLevel' })}
                        >
                          <option key="default" disabled>
                            {intl.formatMessage({ id: 'home.experienceLevel' })}
                          </option>
                          {experienceLevel.map(({ node: { title, originalId } }) => (
                            <option key={originalId} value={originalId}>
                              {title}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </div>
                    <div className={styles.row}>
                      <Field
                        type="number"
                        name="salaryfrom"
                        className={classNames(styles.input, styles.inputSalary, styles.inputSalaryFrom, {
                          [styles.error]:
                            (errors.salaryfrom && touched.salaryfrom) || (errors.salaryfrom && submitCount),
                        })}
                        placeholder={intl.formatMessage({ id: 'home.salaryFrom' })}
                      />
                      <Field
                        type="number"
                        name="salaryup"
                        className={classNames(styles.input, styles.inputSalary, {
                          [styles.error]: errors.salaryup && touched.salaryup,
                        })}
                        placeholder={intl.formatMessage({ id: 'home.salaryUp' })}
                      />
                    </div>
                    <div className={styles.row}>
                      <Field
                        type="text"
                        name="companyName"
                        placeholder={intl.formatMessage({ id: 'home.companyName' })}
                        className={classNames(styles.input, {
                          [styles.error]:
                            (errors.companyName && touched.companyName) || (errors.companyName && submitCount),
                        })}
                      />
                    </div>
                    <div className={styles.row}>
                      <Field
                        type="text"
                        name="aboutCompany"
                        placeholder={intl.formatMessage({ id: 'home.aboutCompany' })}
                        className={classNames(styles.input, styles.textarea, {
                          [styles.error]:
                            (errors.aboutCompany && touched.aboutCompany) || (errors.aboutCompany && submitCount),
                        })}
                        value={aboutCompany}
                        component="textarea"
                      />
                    </div>
                    <div className={styles.row}>
                      <Map
                        className={styles.map}
                        pickCoordinates
                        geolocation={geolocation}
                        onDragEnd={this.handlePickCoordinates}
                      />
                    </div>
                    <div className={styles.row}>
                      <Field
                        type="text"
                        name="city"
                        placeholder={intl.formatMessage({ id: 'home.city' })}
                        className={classNames(styles.input, {
                          [styles.error]: (errors.city && touched.city) || (errors.city && submitCount),
                        })}
                      />
                    </div>
                    <div className={styles.row}>
                      <Field
                        type="text"
                        name="locationDescription"
                        placeholder={intl.formatMessage({ id: 'home.locationDescription' })}
                        className={classNames(styles.input, {
                          [styles.error]:
                            (errors.locationDescription && touched.locationDescription) ||
                            (errors.locationDescription && submitCount),
                        })}
                      />
                    </div>
                    <h3>{intl.formatMessage({ id: 'home.expirationDate' })}</h3>
                    <DatePicker
                      minDate={new Date()}
                      maxDate={addMonths(new Date(), 2)}
                      selected={expirationdate}
                      locale={locale === 'ru' ? ru : en}
                      className={styles.input}
                      popperClassName={styles.datePicker}
                      onChange={(date) => this.setState({ expirationdate: date })}
                    />
                    <div className={styles.row}>
                      <Field
                        type="text"
                        name="contactEmail"
                        placeholder={intl.formatMessage({ id: 'home.contactEmail' })}
                        className={classNames(styles.input, {
                          [styles.error]:
                            (errors.contactEmail && touched.contactEmail) || (errors.contactEmail && submitCount),
                        })}
                      />
                    </div>
                    <div className={styles.row}>
                      <Field
                        type="tel"
                        name="contactNumber"
                        placeholder={intl.formatMessage({ id: 'home.phone' })}
                        className={classNames(styles.input, {
                          [styles.error]:
                            (errors.contactNumber && touched.contactNumber) || (errors.contactNumber && submitCount),
                        })}
                      />
                    </div>
                    <Dropzone
                      multiple={false}
                      accept="image/png, image/jpeg, image/svg+xml, image/webp"
                      onDrop={(acceptedFiles) => this.setState({ companyLogo: acceptedFiles[0] })}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className={styles.dropzoneContainer}>
                          <div {...getRootProps()} className={styles.dropzone}>
                            <input {...getInputProps()} />
                            <p>{intl.formatMessage({ id: 'home.logoFile' })}</p>
                          </div>
                          {companyLogo && (
                            <aside>
                              <h4>{intl.formatMessage({ id: 'home.files' })}</h4>
                              <ul className={styles.fileList}>
                                <li>{companyLogo.name}</li>
                              </ul>
                            </aside>
                          )}
                        </section>
                      )}
                    </Dropzone>
                    <Dropzone
                      accept="image/png, image/jpeg, image/svg+xml, image/webp"
                      onDrop={(acceptedFiles) => this.setState({ photos: acceptedFiles })}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className={styles.dropzoneContainer}>
                          <div {...getRootProps()} className={styles.dropzone}>
                            <input {...getInputProps()} />
                            <p>{intl.formatMessage({ id: 'home.photoFiles' })}</p>
                          </div>
                          {photos.length > 0 && (
                            <aside>
                              <h4>{intl.formatMessage({ id: 'home.files' })}</h4>
                              <ul className={styles.fileList}>
                                {photos.map(({ name }) => <li key={name}>{name}</li>)}
                              </ul>
                            </aside>
                          )}
                        </section>
                      )}
                    </Dropzone>
                    <div className={classNames(styles.row, styles.column)}>
                      <button type="submit" className={styles.button}>
                        <FormattedMessage id="home.sendRequest" />
                      </button>
                    </div>
                  </Form>
                )
              }}
            />
          </div>
        )}
      </div>
    )
  }
}

export default injectIntl(AddJob)
