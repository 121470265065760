import React, { Component } from 'react'
import ReactMapboxGl, { Marker, Popup, Feature, Layer } from 'react-mapbox-gl'
import Pin from 'src/components/svg/Pin'
import styles from './Map.module.scss'
import classNames from 'classnames'

const Map = ReactMapboxGl({
  accessToken: 'pk.eyJ1IjoiaHVtYW5zZWVsYWJzIiwiYSI6ImNqc2E2cXNsMDAwbnc0M3FjbWk0eDEwYjMifQ.H07F0Qxm3Di5BY6DYVb0OA',
})

const mapBoxStyle = { style: 'mapbox://styles/mapbox/streets-v9' }
const containerStyle = {
  height: '100%',
  width: '100%',
}
const layoutLayer = { 'icon-image': 'circle-15' }

class MyMap extends Component {
  state = { isPopUpVisible: false }

  togglePopup = () => {
    this.setState({ isPopUpVisible: !this.state.isPopUpVisible })
  }

  render() {
    const {
      geolocation: { latitude, longitude },
      pickCoordinates,
      onDragEnd,
      style,
      className,
    } = this.props

    return (
      <div className={classNames(className, styles.root)} style={style}>
        <Map
          style={mapBoxStyle.style}
          containerStyle={containerStyle}
          center={[longitude, latitude]}
          zoom={[12]}
          onClick={this.handleMapClick}
        >
          {!pickCoordinates && (
            <Marker onClick={this.togglePopup} coordinates={[longitude, latitude]} className={styles.marker}>
              <Pin fill="#9143fd" width="40px" height="auto" />
            </Marker>
          )}
          {pickCoordinates && (
            <Layer type="symbol" id="marker" layout={layoutLayer}>
              <Feature draggable onDragEnd={onDragEnd} coordinates={[longitude, latitude]} />
            </Layer>
          )}
          {this.state.isPopUpVisible && (
            <Popup
              coordinates={[longitude, latitude]}
              offset={{
                bottom: [0, -48],
              }}
              className={styles.popup}
            >
              <span>We are here</span>
            </Popup>
          )}
        </Map>
      </div>
    )
  }
}

export default MyMap
