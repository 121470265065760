import React from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './Thanks.module.scss'
import submit from 'src/img/submit.svg'

const Thanks = ({ successMessageId }) => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.application}>
          <FormattedMessage
            id={successMessageId}
            values={{
              breakingLine: <br />,
            }}
          />
        </div>
        <div className={styles.galochka}>
          <img src={submit} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Thanks
