import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PageHelmet from 'src/components/PageHelmet'
import Header from 'src/components/Header'
import AddJob from 'src/components/AddJob'
import Footer from 'src/components/Footer'
import withLayout from 'src/components/withLayout'
import { injectIntl } from 'react-intl'

class addJob extends Component {
  render() {
    const {
      data: {
        allDatoCmsExperienceLevel: { edges: experienceLevel },
        allDatoCmsJobTag: { edges: jobTag },
        allDatoCmsPosition: { edges: position },
      },
      locale,
      intl,
    } = this.props
    return (
      <div>
        <PageHelmet title={intl.formatMessage({ id: 'home.addNewJob' })} defer={false} />
        <Header locale={locale} full={true} />
        <AddJob locale={locale} experienceLevel={experienceLevel} jobTag={jobTag} position={position} />
        <Footer locale={locale} />
      </div>
    )
  }
}

export const query = graphql`
  query AddJob($locale: String!) {
    allDatoCmsExperienceLevel(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          originalId
          title
        }
      }
    }
    allDatoCmsJobTag(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          originalId
          title
        }
      }
    }
    allDatoCmsPosition(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          originalId
          title
        }
      }
    }
  }
`

const customProps = {
  localeKey: 'addJob',
}

export default withLayout(customProps)(injectIntl(addJob))
